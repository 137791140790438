var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_c('v-col',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('h3',{staticClass:"mb-auto"},[_vm._v(_vm._s(_vm.item.data.id))]),_c('h3',{staticClass:"mb-auto"},[_vm._v(_vm._s(_vm.item.data.type))]),_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.item.data.dateTime))]),_c('div',[_vm._v("Организация: "+_vm._s(_vm.item.data.organization))]),_c('div',[_vm._v("Точка: "+_vm._s(_vm.item.data.hostname))])])],1),_c('td',[_c('v-col',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('h3',{staticClass:"mb-auto"},[_vm._v(_vm._s(_vm.item.worker.name))]),_c('span',[_vm._v("Возраст: "+_vm._s(_vm.item.worker.age))]),_c('span',[_vm._v("Табельный №: "+_vm._s(_vm.item.worker.personellNumber))])])],1),_c('td',[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"mb-auto"},[_c('ResultBadge',{attrs:{"result":_vm.item.result,"type":_vm.item.type,"failReasons":_vm.item.failReasons}})],1),_c('span',[_vm._v("Медработник: "+_vm._s(_vm.item.data.medworker))]),(_vm.item.data.comment)?_c('span',[_vm._v("Комментарий: "+_vm._s(_vm.item.data.comment))]):_vm._e()]),(_vm.$can('READ_INSPECTIONS_DETAILED'))?_c('v-col',{staticClass:"pa-0 pl-3",attrs:{"cols":"4"}},_vm._l((_vm.medicalData),function(ref){
var label = ref.label;
var value = ref.value;
return _c('span',{key:label,staticStyle:{"display":"block","white-space":"nowrap"}},[(value)?[_vm._v(_vm._s(label)+": "+_vm._s(value))]:_vm._e()],2)}),0):_vm._e()],1)],1),(_vm.$can('READ_INSPECTIONS_DETAILED') || _vm.$can('DOWNLOAD_MEDRESULT') || _vm.$can('PRINT_STICKERS'))?_c('td',[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$can('READ_INSPECTIONS_DETAILED'))?_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'inspections:card', params: { id: _vm.id } }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1):_vm._e()]}}],null,false,1965747111)},[_c('span',[_vm._v("Просмотреть карточку осмотра")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$can('DOWNLOAD_MEDRESULT'))?_c('v-btn',_vm._g({attrs:{"icon":"","download":_vm.item.data.pdfName,"target":"_blank","href":_vm.item.data.pdfUrl}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-download")])],1):_vm._e()]}}],null,false,1470834444)},[_c('span',[_vm._v(" Скачать файл результата")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$can('PRINT_STICKERS'))?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.printSticker}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-print")])],1):_vm._e()]}}],null,false,3517520285)},[_c('span',[_vm._v("Повторная печать стикера")])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }