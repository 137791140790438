<template lang="pug">
  v-select(
    v-model="internalValue"
    label="Тип осмотра"
    :items="items"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    hide-details="auto"
    multiple
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true }"
    :errorMessages="errorMessages"
  )
    template(v-slot:prepend-item)
      v-list-item(@click="toggleAll")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="!!selectedCount"
            :indeterminate="!selectedCount ? false : !isAllItemsSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все типы осмотров
    template(v-slot:selection="{ item, index }")
      div(
        v-if="index === 0"
        class="v-select__selection v-select__selection--comma"
      )
        span(v-if="isAllItemsSelected") Все типы
        span(v-else) {{ item.name }}
      span(
        v-if="index === 1 && !isAllItemsSelected"
        class="grey--text caption"
      ) + еще {{internalValue.length - 1}}

</template>

<script>
import { InspectionTypes } from '@/utils/constants.js';

const InspectionTypeItems = Object.entries(InspectionTypes)
  .map(([id, name]) => ({ id, name }))
  .slice(0, -1);

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    availableTypes: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  data() {
    return {
      internalValue: [],
    };
  },
  computed: {
    items() {
      let types = null;
      if (this.availableTypes) {
        types = this.availableTypes.map(type => {
          const name = InspectionTypeItems.find(o => o.id === type).name;
          return { id: type, name };
        });
      }
      return types || Object.freeze(InspectionTypeItems);
    },
    selectedCount() {
      return this.internalValue.length;
    },
    isAllItemsSelected() {
      return this.internalValue.length === this.items.length;
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== this.internalValue) {
        this.internalValue = value
          ? value.slice()
          : this.items.map(item => item.id);
      }
    },
    internalValue(value) {
      this.$emit(
        'input',
        value.length === (this.availableTypes || InspectionTypeItems).length
          ? null
          : value,
      );
    },
    availableTypes() {
      this.setInternalValue();
    },
  },
  mounted() {
    this.setInternalValue();
  },
  methods: {
    setInternalValue() {
      this.internalValue = this.value
        ? this.value.slice()
        : this.items.map(item => item.id);
    },
    toggleAll() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) {
          this.internalValue = [];
        } else {
          this.internalValue = this.items.map(item => item.id);
        }
      });
    },
  },
};
</script>
