<template lang="pug">
  v-dialog(
    :value="showDialog"
    @input="open"
    max-width="656"
    persistent
    overlay-color="#000000"
    overlay-opacity="0.5"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        depressed
        color="primary"
        v-on="on"
      ) Перейти к осмотру по ID
    v-card
      v-card-title Перейти к осмотру по ID
      v-card-text
        v-form
          v-row
            v-col(cols="12" sm="12")
              v-text-field(
                v-model="id"
                label="Введите ID осмотра"
                type="number"
                autofocus
                :error-messages="error"
              )

          v-row(align-end)
            v-col(cols="12" sm="6")
              v-btn(
                @click="close"
                block
              ) Отмена
            v-col(cols="12" sm="6")
              v-btn(
                @click="onSubmit"
                color="primary"
                depressed
                block
                :loading="$root.isLoading('inspection') || $root.isLoading('inspection-documents')"
                :disabled="!id"
              ) Перейти к осмотру
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      id: null,
      error: null,
    };
  },

  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },
  methods: {
    handleEnter(event) {
      if (event.key !== 'Enter') return null;
      event.preventDefault();

      if (!this.id) return null;
      this.onSubmit();
    },

    open() {
      this.showDialog = true;
      document.addEventListener('keypress', this.handleEnter);
    },

    close() {
      this.id = null;
      this.showDialog = false;
      document.removeEventListener('keypress', this.handleEnter);
    },

    async onSubmit() {
      this.error = null;

      try {
        await this.$router.push({
          name: 'inspections:card',
          params: { id: this.id, isFetched: true },
        });
      } catch (error) {
        const status = error?.response?.status;
        const errList = {
          404: 'Осмотр не найден.',
          403: 'У вас нет прав на просмотр данного осмотра.',
        };

        this.error = errList[status] || 'Ошибка. Попробуйте позже.';
      }
    },
  },
};
</script>
