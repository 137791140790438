var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-width":200,"close-on-content-click":false,"disabled":_vm.items.length == 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g({ref:"textField",staticClass:"smart-select",attrs:{"label":_vm.label,"placeholder":_vm.menu ? _vm.selectStatus : _vm.placeholder,"value":_vm.menu ? _vm.search : _vm.selectStatus,"readonly":!_vm.menu,"autocomplete":"off","disabled":_vm.items.length == 0,"append-icon":_vm.$vuetify.icons.values.dropdown,"hide-details":_vm.hideDetails,"outlined":_vm.outlined,"dense":_vm.dense,"loading":_vm.loading},on:{"input":function($event){_vm.search = $event}},nativeOn:{"click":function($event){_vm.menu = true}}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.items.length > 0)?_c('v-list',[(!_vm.search)?[_c('v-list-item',{on:{"click":_vm.toggleAll}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"value":_vm.selectedCount != 0,"indeterminate":!_vm.selectedCount ? false : !_vm.isAllItemsSelected},on:{"input":_vm.toggleAll}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Выбрать все организации")])],1)],1),_c('v-divider')]:_vm._e(),(_vm.innerItems.length == 0 && _vm.items.length != 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v(" Ничего не найдено")])],1)],1):_vm._e(),_c('v-virtual-scroll',{attrs:{"bench":"20","max-height":"304","item-height":"48","items":_vm.innerItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.toggleItem(item.id)}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isChecked(item.id)},on:{"input":function($event){return _vm.toggleItem(item.id)}}})],1),(item.name.length < 35)?_c('v-list-item-content',[_vm._v(_vm._s(item.name))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_vm._v(_vm._s(item.name.slice(0, 33))+"...")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}}],null,false,2541570090)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }