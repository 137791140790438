<template lang="pug">
ListPageLayout(title="Список осмотров")
  template(#filters)
    v-col(cols="3")
      SearchWorker(
        :disabled="listIsLoading"
        :loading="listIsLoading"
        v-model="query.employee"
      )
    v-col(cols="4" )
      DateRangePicker(
        label="Диапазон осмотров"
        :disabled="listIsLoading"
        v-model="date"
        :min="minDate"
        outlined
        dense
        :hint="$can('READ_INSPECTIONS_ALL') ? null : 'Доступны последние 3 дня'"
      )
    v-col.text-right(cols="5")
      div
        v-btn.mr-5(
          outlined
          :disabled="listIsLoading"
          @click="resetFilters"
        ) Сбросить
        v-btn(
          color="primary"
          depressed
          :disabled="isDisabled || !isChangedFilters"
          @click="fetchInspections"
        ) Применить
      span {{isChangedFilters ? "Фильтры изменены, примените изменения" : 'Измените фильтры' }}


    v-col(col="6" sm="3" lg="2")
      SelectType(
        v-model="query.types"
        :availableTypes="availableTypes"
        :disabled="listIsLoading"
        outlined
        dense
      )
    v-col(cols="6" sm="3" lg="2")
      SelectResult(
        v-model="query.results"
        :disabled="listIsLoading"
      )
    v-col(cols="6" sm="3" lg="2")
      SimpleMultipleSelect(
        v-model="query.orgIds"
        label="Организация"
        placeholder="Выберите организацию"
        :items="organizations"
        :disabled="listIsLoading"
        :loading="$root.isLoading('organizations:names')"
        dense
        outlined
        hide-details="auto"
      )
    v-col(cols="6" sm="3" lg="2")
      SelectHostname(
        v-model="query.hostnames"
        :items="hostnames"
        :disabled="listIsLoading"
        :organizations="query.orgIds"
        outlined
        orgHostBinded
        dense
      )

  template(#actions)
    span.mr-6
      GetInspectionById(v-if="$can('READ_INSPECTIONS_DETAILED')")
    ExportButton(
      v-if="$can('EXPORT_INSPECTIONS')"
      :disabled="listIsLoading || (listTotal > 10000)"
      :query="listQuery"
      :action="exportAction"
      fileNamePrefix="inspections"
      :total="listTotal"
    )

  template(#list)
    v-data-table(
      loading-text=""
      :headers="headers"
      :items="listItems"
      item-key="id"
      :items-per-page.sync="listLimit"
      :loading="listIsLoading"
      no-data-text="Нет осмотров по данным фильтрам"
      hide-default-footer
      )

        template(#header)
          PerPagePicker(
            v-model="query.limit"
            data-table
            @input="fetchInspections"
          )

        template(#progress)
          Spinner

        template(#item="{ item }")
          ListItem(:id="item.id")

  template(#footer)
    v-col(lg="12" sm="12" align="center")
       v-pagination(
         :disabled="listIsLoading"
          v-show="listShowPagination"
          v-model="query.page"
          :length="listTotalPages"
          total-visible="10"
          @input="fetchInspections"
        )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

// Filters components
import ExportButton from '@/components/ExportButton';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';
import DateRangePicker from '@/components/controls/DateRangePicker.vue';
import SearchWorker from '@/views/inspections/controls/SearchWorker.vue';
import SelectResult from '@/views/inspections/controls/SelectResult.vue';
import SelectType from '@/components/controls/SelectType.vue';
import SelectHostname from '@/components/controls/SelectHostname.vue';
import GetInspectionById from '@/views/inspections/controls/GetInspectionById';
import SimpleMultipleSelect from '@/components/controls/SimpleMultipleSelect.vue';

// List components
import ListPageLayout from '@/components/layouts/ListPageLayout';
import PerPagePicker from '@/components/controls/PerPagePicker';
import Spinner from '@/components/Spinner.vue';
import ListItem from '@/views/inspections/list/ListItem';

import resourceListFactory from '@/utils/mixins/resourceListFactory';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export default {
  // FIXME: make some of 'em lazy
  components: {
    ExportButton,
    SearchWorker,
    OrganizationPicker,
    DateRangePicker,
    SelectResult,
    GetInspectionById,
    SelectType,
    SelectHostname,
    SimpleMultipleSelect,

    ListPageLayout,
    PerPagePicker,
    ListItem,
    Spinner,
  },

  extends: resourceListFactory('INSPECTION_LIST', [], 'осмотров', {
    createMutations: false,
    autoWatch: false,
  }),
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      // if(vm.$store.state.INSPECTION_LIST.listStatus) !== 'not_loaded') ;
      const workerId = Number(to.query.workerId);
      if (
        vm.$store.state.INSPECTION_LIST.listStatus !== 'not_loaded' &&
        workerId
      ) {
        // vm.resetListFilters();
        await vm.setSearchEmployee(Number(workerId));
        vm.fetchInspections();
      }

      next();
    });
  },

  data() {
    return {
      headers: [
        { text: 'Осмотр', value: 'inspection', sortable: false },
        { text: 'Работник', value: 'worker', sortable: false },
        { text: 'Результат', value: 'results', sortable: false },
        { value: 'actions', sortable: false },
      ],
      query: {},
      availableTypes: null,
    };
  },

  computed: {
    ...mapGetters(['organizations', 'hostnames']),

    date: {
      get() {
        return {
          from: this.query.dateStart,
          to: this.query.dateEnd,
        };
      },
      set(value) {
        this.query.dateStart = value.from;
        this.query.dateEnd = value.to;
      },
    },

    minDate() {
      return !this.$can('READ_INSPECTIONS_ALL')
        ? DateTime.local().minus({ days: 3 }).toFormat('yyyy-MM-dd')
        : null;
    },

    isDisabled() {
      return (
        this.$root.isDisabled('inspections') ||
        this.query.orgIds?.length === 0 ||
        this.query.hostnames?.length === 0 ||
        this.query.types?.length === 0 ||
        this.query.results?.length === 0 ||
        (!this.query.dateStart && !this.query.dateEnd)
      );
    },

    isChangedFilters() {
      const keys = [
        'dateStart',
        'dateEnd',
        'orgIds',
        'hostnames',
        'employee',
        'results',
        'types',
      ];
      return !isEqual(pick(this.listQuery, keys), pick(this.query, keys));
    },

    exportAction() {
      return `INSPECTION_LIST/export`;
    },
  },

  watch: {
    listQuery: {
      immediate: true,
      deep: true,
      handler(value) {
        this.query = { ...value };
      },
    },
    'query.orgIds': {
      immediate: true,
      handler(value) {
        this.syncAvailableTypes(value);
      },
    },
  },

  methods: {
    ...mapActions('INSPECTION_LIST', ['resetListFilters']),
    async beforeFirstFetchList() {
      if (this.$can('READ_INSPECTIONS_ALL')) {
        if (!this.changedDate()) {
          this.setQueryDate(
            DateTime.local().minus({ days: 3 }).toFormat('yyyy-MM-dd'),
            DateTime.local().toFormat('yyyy-MM-dd'),
          );
        }
      }
      await this.setSearchEmployee(Number(this.$route.query.workerId));
    },
    async setSearchEmployee(id) {
      if (!id) return;
      await this.resetListFilters();

      if (this.$can('READ_INSPECTIONS_ALL')) {
        this.setQueryDate(null, null);
      } else {
        this.setQueryDate(
          DateTime.local().minus({ days: 3 }).toFormat('yyyy-MM-dd'),
          DateTime.local().toFormat('yyyy-MM-dd'),
        );
      }

      const [employee] = await this.$store.dispatch('searchWorker', id);
      this.$store.commit('INSPECTION_LIST/employee', employee);
    },
    setQueryDate(from, to) {
      this.$store.commit(`INSPECTION_LIST/dateStart`, from);
      this.$store.commit(`INSPECTION_LIST/dateEnd`, to);
    },
    changedDate() {
      const start = DateTime.local().minus({ days: 3 }).toFormat('yyyy-MM-dd');
      const end = DateTime.local().toFormat('yyyy-MM-dd');
      return (
        this.listQuery.dateStart !== start || this.listQuery.dateEnd !== end
      );
    },
    resetFilters() {
      this.resetListFilters();
      this.$router.push({ name: 'inspections:list' });
      this.fetchList();
    },
    fetchInspections() {
      this.fetchList(this.query);
    },
    async syncAvailableTypes(orgIds) {
      this.availableTypes = await this.$store.dispatch(
        'getAvailableTypes',
        orgIds,
      );

      if (this.query.types && this.availableTypes) {
        // if more types than are available to us are selected,
        // remove types that are not available to us
        this.query.types = this.query.types.filter(item =>
          this.availableTypes.includes(item),
        );
      }
    },
  },
};
</script>
