<template lang="pug">
v-menu(
  ref="menu"
  :close-on-content-click="false"
  :return-value.sync="innerValue"
  transition="scale-transition"
  offset-y
  min-width="290px"
)
  template(#activator="{ on, attrs }")
    v-text-field(
      :value="dateRangeText"
      :label="label"
      append-icon="fa-calendar"
      readonly
      required
      :outlined="outlined"
      hide-details="auto"
      :dense="dense"
      placeholder="дд-мм-гггг"
      v-bind="attrs"
      :error-messages="errorMessages"
      :disabled="disabled"
      :hint="hint"
      persistent-hint
      v-on="on"
      :clearable="clearable"
      @click:clear="close"
    )
  v-date-picker(
    v-model="innerValue"
    :min="minComputed"
    :max="maxComputed"
    first-day-of-week="1"
    @input="updateDate"
    no-title
    scrollable
    range
  )
    v-spacer
    v-btn(text @click="close") Отменить
    v-btn(text color="primary" @click="save") Сохранить
</template>

<script>
import {
  currentDateTime,
  getHighestDate,
  getLowestDate,
  parseDate,
} from '@/utils/helpers';

export default {
  props: {
    value: { type: Object, required: true },
    itemFrom: {
      type: String,
      default: 'from',
    },
    itemTo: {
      type: String,
      default: 'to',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    label: {
      required: true,
      type: String,
    },
    min: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    clearable: Boolean,
    maxRange: {
      type: Number,
      default: null,
    },

    // look
    outlined: Boolean,
    dense: Boolean,
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerValue: [],
    };
  },
  computed: {
    minComputed() {
      return getHighestDate(
        parseDate(this.min),
        this.maxRange && this.from
          ? parseDate(this.from).minus({ days: this.maxRange })
          : undefined,
        parseDate('2017-01-01'),
      ).toFormat('yyyy-LL-dd');
    },
    maxComputed() {
      return getLowestDate(
        this.maxRange && this.from
          ? parseDate(this.from).plus({ days: this.maxRange })
          : undefined,
        currentDateTime(),
      ).toFormat('yyyy-LL-dd');
    },
    from() {
      return this.value[this.itemFrom];
    },
    to() {
      return this.value[this.itemTo];
    },
    dateRangeText() {
      return [this.from, this.to].filter(item => item).join(' – ');
    },
  },
  watch: {
    value: {
      handler() {
        // v-date-picker compares the value by array reference, not value
        // if you remove the check and always overwrite the array,
        // v-date-picker will think that a new value is passed each time
        // and date period selection will break
        if (this.from !== this.innerValue[0] || this.to !== this.innerValue[1])
          this.innerValue = [this.from, this.to];
      },
      immediate: true,
    },
  },
  methods: {
    updateDate(dates) {
      let from = dates[0];
      let to = dates[1];
      if (dates.length === 2) {
        from = getLowestDate(...dates);
        to = getHighestDate(...dates);
      }
      this.$emit('input', {
        [this.itemFrom]: from,
        [this.itemTo]: to,
      });
    },
    save() {
      this.updateDate(this.innerValue);
      this.$refs.menu.save(this.innerValue);
    },
    close() {
      this.updateDate([]);
      this.innerValue = [];
      this.$refs.menu.save([]);
    },
  },
};
</script>
