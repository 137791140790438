<template lang="pug">
  v-menu(
    v-model="menu"
    offset-y
    :nudge-width="200"
    :close-on-content-click="false"
    :disabled="items.length == 0"
  )
    template(#activator="{on, attrs}")
      v-text-field(
        ref="textField"
        class="smart-select"
        :label="label"
        :placeholder="menu ? selectStatus : placeholder"
        :value="menu ? search : selectStatus"
        :readonly="!menu"
        v-on="on"
        autocomplete="off"
        :disabled="items.length == 0"
        @input="search = $event"
        @click.native="menu = true"
        :append-icon="$vuetify.icons.values.dropdown"
        :hide-details="hideDetails"
        :outlined="outlined"
        :dense="dense"
        :loading="loading"
      )
    v-list(v-if="items.length > 0")

      // Pick all orgs
      template(v-if="!search")
        v-list-item(@click="toggleAll")
          v-list-item-action
            v-simple-checkbox(
              @input="toggleAll"
              :value="selectedCount != 0"
              :indeterminate="!selectedCount ? false : !isAllItemsSelected"
            )
          v-list-item-content
            v-list-item-title Выбрать все организации
        v-divider

      // Search no results
      v-list-item(v-if="innerItems.length == 0 && items.length != 0")
        v-list-item-content
          v-list-item-title.text-center  Ничего не найдено

      // Virtual items
      v-virtual-scroll(
        bench="20"
        max-height="304"
        item-height="48"
        :items="innerItems"
      )
        template(#default="{ item }")
          v-list-item(
            :key="item.id"
            @click="toggleItem(item.id)"
          )
            v-list-item-action
              v-simple-checkbox(
                color="primary"
                :value="isChecked(item.id)"
                @input="toggleItem(item.id)"
              )
            v-list-item-content(v-if="item.name.length < 35") {{item.name}}
            v-tooltip(v-else bottom)
              template(#activator="{ on, attrs }")
                v-list-item-content(v-bind="attrs" v-on="on") {{item.name.slice(0, 33)}}...
              span {{item.name}}
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },

    loading: Boolean,
    // look params
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    dense: Boolean,
    outlined: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      search: '',
    };
  },
  computed: {
    selectStatus() {
      if (!this.value) return '';
      else if (this.isAllItemsSelected) return 'Все организации';
      else if (this.value.length === 1)
        return this.items.find(item => item.id === this.value[0])?.name;
      else if (this.value.length === 0) return '';

      return 'Выбрано ' + this.value.length + ' организаций';
    },
    innerItems() {
      if (!this.search) return this.items;
      else {
        return this.items.filter(
          item =>
            item.name
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1,
        );
      }
    },
    selectedCount() {
      return this.value?.length || 0;
    },
    isAllItemsSelected() {
      return this.value?.length === this.items?.length;
    },
  },
  watch: {
    menu(value) {
      if (value === true) this.search = '';
    },
  },
  methods: {
    isChecked(id) {
      if (!this.value) return false;
      return this.value.includes(id);
    },
    toggleAll() {
      this.setFocus();

      this.$nextTick(() => {
        if (this.isAllItemsSelected) this.handleChange([]);
        else this.handleChange(this.items.map(({ id }) => id));
      });
    },
    toggleItem(id) {
      this.setFocus();

      if (this.isChecked(id)) {
        const index = this.value.findIndex(item => item === id);
        return this.handleChange([
          ...this.value.slice(0, index),
          ...this.value.slice(index + 1),
        ]);
      } else return this.handleChange([id, ...this.value]);
    },

    setFocus() {
      this.$refs.textField.focus();
    },

    handleChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.smart-select {
  & .v-input__slot,
  & .v-text-field__slot input {
    cursor: pointer !important;
  }

  & .v-input__icon--append {
    pointer-events: none;
  }

  &.v-input--is-focused .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
}
</style>
