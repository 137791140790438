<template lang="pug">
  v-autocomplete(
    ref="input"
    :value="value"
    return-object
    label="Поиск работника"
    :items="foundWorkers"
    item-text="fullname"
    item-value="id"
    outlined
    dense
    hide-details="auto"
    hide-no-data
    hint="ФИО в строгом порядке, или табельный номер, или телефон"
    :clearable="searchStr != null"
    :disabled="disabled"
    :loading="loading"
    :search-input.sync="searchStr"
    no-filter
    @click:clear="$emit('input', null)"
    @change="onChange"
  )
</template>

<script>
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      searchStr: '',
      foundWorkers: [],
      loading: false,
    };
  },

  watch: {
    searchStr(value, oldVal) {
      if (value !== oldVal) this.handleWorkerInput();
    },
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        // when new object with worker comes to component,
        // we need to show it as selected in v-autocomplete
        // so we add worker to  array of items (foundWorkers)
        // otherwise it just won't display by v-autocomplete
        if (value && !this.foundWorkers.find(({ id }) => id === value.id))
          this.foundWorkers.unshift(value);
      },
    },
  },

  methods: {
    ...mapActions(['searchWorker']),

    onChange(e) {
      this.$refs['input'].blur();
      this.$emit('input', e || null);
    },

    handleWorkerInput: debounce.call(
      this,
      async function () {
        // when user selects worker from foundWorkers list,
        // there is no need to make a request to the server
        if (this.loading || this.value?.fullname === this.searchStr) return;

        this.loading = true;
        this.foundWorkers = [];
        this.foundWorkers = await this.searchWorker(this.searchStr);
        this.loading = false;
      },
      1000,
    ),
  },
};
</script>
