<template lang="pug">
tr
  td
    v-col.d-flex.flex-column(style="height: 100%")
      h3.mb-auto {{item.data.id}}
      h3.mb-auto {{item.data.type}}
      div(style="white-space:nowrap;") {{item.data.dateTime}}
      div Организация: {{item.data.organization}}
      div Точка: {{item.data.hostname}}
  td
    v-col.d-flex.flex-column(style="height: 100%")
      h3.mb-auto {{item.worker.name}}
      span Возраст: {{item.worker.age}}
      span Табельный №: {{item.worker.personellNumber}}

  td
    v-col.d-flex(style="height: 100%")
      v-col.d-flex.flex-column.pa-0(cols="4")
        div.mb-auto
          ResultBadge(
            :result="item.result"
            :type="item.type"
            :failReasons="item.failReasons"
          )
        span Медработник:
          |  {{item.data.medworker}}
        span(v-if="item.data.comment") Комментарий: {{item.data.comment}}
      v-col.pa-0.pl-3(cols="4" v-if="$can('READ_INSPECTIONS_DETAILED')")
        span(
          v-for="{label, value} in medicalData"
          :key="label"
          style="display:block;white-space:nowrap;"
          )
          template(v-if="value") {{label}}: {{value}}

  td(v-if="$can('READ_INSPECTIONS_DETAILED') || $can('DOWNLOAD_MEDRESULT') || $can('PRINT_STICKERS')")
    div.d-flex.flex-column.align-center
      v-tooltip(left)
        template(v-slot:activator="{ on }")
          v-btn(
            v-if="$can('READ_INSPECTIONS_DETAILED')"
            v-on="on"
            icon
            :to="{ name: 'inspections:card', params: { id } }"
          )
            v-icon(small) fa-eye
        span Просмотреть карточку осмотра
      v-tooltip(left)
        template(v-slot:activator="{ on }")
          v-btn(
            v-if="$can('DOWNLOAD_MEDRESULT')"
            v-on="on"
            icon
            :download="item.data.pdfName"
            target="_blank"
            :href="item.data.pdfUrl"
          )
            v-icon(small) fa-download
        span  Скачать файл результата
      v-tooltip(left)
        template(v-slot:activator="{ on }")
          v-btn(
            v-if="$can('PRINT_STICKERS')"
            v-on="on"
            icon
            @click="printSticker"
          )
            v-icon(small) fa-print
        span Повторная печать стикера
</template>

<script>
// FIXME: Rework template&layout of this component
import ResultBadge from '../view/ResultBadge';
import eventBus from '@/plugins/eventBus';

export default {
  components: {
    ResultBadge,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    item() {
      return this.$store.getters['INSPECTION_LIST/inspection'](this.id);
    },
    medicalData() {
      return [
        {
          label: 'Артериальное давление, мм рт. ст.',
          value: this.item.medical.pressure,
        },
        { label: 'Пульс, уд./мин', value: this.item.medical.pulse },
        { label: 'Алкоголь, мг/л', value: this.item.medical.alcohol },
        { label: 'Жалобы', value: this.item.medical.complaints },
        { label: 'Сон', value: this.item.medical.sleep },
        { label: 'Температура, °C', value: this.item.medical.temperature },
        { label: 'Тип тестирования', value: this.item.medical.reactionType },
        {
          label: 'Результат тестирования',
          value: this.item.medical.reactionResult,
        },
      ];
    },
  },
  methods: {
    printSticker() {
      eventBus.$emit('showenPrintStickerModal', {
        inspectionId: this.id,
        organizationId: this.item.worker.organizationId,
        hostnameId: this.item.data.hostname,
      });
    },
  },
};
</script>
