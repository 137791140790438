<template lang="pug">
  v-select(
    :value="value"
    @input="handleChange"
    outlined
    dense
    hide-details="auto"
    label="Результат осмотра"
    :items="selectItems"
    :disabled="disabled"
    multiple
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true }"
  )
    template(#prepend-item)
      v-list-item(@click="toggleAll")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="!!value.length"
            :indeterminate="!value.length ? false : !allSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все
      v-list-item(@click="togglePositive")
        v-list-item-action
          v-simple-checkbox(
            @input="togglePositive"
            :value="allPositiveSelected"
          )
        v-list-item-content
          v-list-item-title Допущенные
      v-list-item(@click="toggleAllNegative")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAllNegative"
            :value="!!negativeSelectedCount"
            :indeterminate="indeterminateNegative"
          )
        v-list-item-content
          v-list-item-title Недопущенные
      v-divider.mb-2

    template(#selection="{ item, index }")
      div(v-if="index === 0")
        span(v-if="allSelected") Все результаты
        span(v-else-if="value.length === 1") {{item.name}} 
        span(v-else) Выбрано вариантов: {{value.length}}

</template>

<script>
import { InspectionFailReasons } from '@/utils/constants.js';

// we remove last element which is fallback for 'undefined'
const FailReasonsIds = Object.keys(InspectionFailReasons).slice(0, -1);

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    disabled: Boolean,
  },
  computed: {
    // all possible RESULTS
    items() {
      // 'true' is needed cuz backend expects it as flag that all positive
      // inspections are selected
      return [...FailReasonsIds, true];
    },

    // all possible RESULTS in format for v-select ({id: .., name: ..})
    selectItems() {
      const selectItems = FailReasonsIds.map(id => ({
        id,
        ...InspectionFailReasons[id],
      }));
      selectItems.push({ id: true, name: 'Допущенные' });
      return selectItems;
    },

    allSelected() {
      return this.value.length === this.items.length;
    },
    allPositiveSelected() {
      return this.value.includes(true);
    },

    negativeSelectedCount() {
      let count = 0;
      this.value.forEach(item => {
        if (FailReasonsIds.includes(item)) count++;
      });
      return count;
    },

    indeterminateNegative() {
      return (
        this.negativeSelectedCount < FailReasonsIds.length &&
        this.negativeSelectedCount > 0
      );
    },
  },

  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.handleChange([]);
        } else {
          this.handleChange([true, ...FailReasonsIds]);
        }
      });
    },
    togglePositive() {
      this.$nextTick(() => {
        if (this.allPositiveSelected) {
          this.handleChange(this.value.filter(item => item !== true));
        } else {
          this.handleChange([...this.value, true]);
        }
      });
    },
    toggleAllNegative() {
      this.$nextTick(() => {
        const positive = this.allPositiveSelected;
        if (this.negativeSelectedCount === FailReasonsIds.length) {
          this.handleChange(positive ? [true] : []);
        } else {
          this.handleChange(
            positive ? [positive, ...FailReasonsIds] : [...FailReasonsIds],
          );
        }
      });
    },
    handleChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
